import React from "react";

// Context
import {withAuthentication} from "../components/Session";

// Pages
import Signup from "../components/Signup";

function SignupView() {
  return <Signup/>;
}

export default withAuthentication(SignupView);
