// React
import React from "react";
import PropTypes from "prop-types";

// Components
import Link from "../Link";
// import TermsCheckbox from "../TermsCheckbox";

// Constants
import * as routes from "../../constants/routes";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import {
  Paper, 
  Grid, 
  Button, 
  Typography, 
  TextField, 
  RadioGroup,
  Radio, 
  FormControl,
  FormLabel,
  FormControlLabel
} from "@material-ui/core";

// Other libs
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center" /* horizontal */,
    justifyContent: "center", /* vertical */
    background: "#f6f7fb", //"#f6f7fb",
  },
  form: {
    width: "100%",
  },
  content: {
    width: 400,
    maxWidth: "90%",
    margin: "auto",
    textAlign: "center",
  },
  loginCard: {
    marginBottom: 20,
  },
  mainHeaderText: {
    fontSize: "1.6rem",
    fontWeight: 500,
    textAlign: "center",
    padding: 0,
    marginTop: 20,
  },
  mainCtaButton: {
    width: "100%",
  },
  gridContainer: {
    margin: "auto",
    width: "inherit",
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 20,
  },
  roleField: {
    marginTop: 7,
  },
  roleFieldLabel: {
    marginBottom: 7,
  },
  radioButton: {
    marginRight: 5,
  },
  termsText: {
    paddingLeft: 5,
  },
  checkbox: {},
  checkboxError: {
    color: theme.palette.error.main,
  },
  errorText: {
    color: theme.palette.error.main,
  },
  normalText: {},
  logo: {
    height: 32,
    marginBottom: theme.spacing(3),
  },
}));

export default function View(props) {
  const {
    firstName,
    lastName,
    role,
    email,
    password,
    // termsAccepted,
    handleChange,
    handleSignUpClick,
    // handleToggleTerms,
    errorMessages,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <img
          alt="Maxer logo full"
          className={classes.logo}
          src="https://images.ctfassets.net/h6w739vsk2p5/1ynYezPX2cmyxd0twklHIC/66f8c80540ba413e31281a96572ca653/maxer-logo-full.svg"
        />
        <form className={classes.form} autoComplete="off">
          <Paper className={classes.loginCard}>
            <Grid container spacing={1} className={classes.gridContainer}>
              <Grid item xs={12}>
                <Typography
                  className={classes.mainHeaderText}
                  component="h1"
                  variant="h3"
                  gutterBottom
                >
                  Create an account
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="firstName"
                  label="First name"
                  className={classNames(classes.textField, classes.dense)}
                  margin="dense"
                  fullWidth={true}
                  value={firstName || ""}
                  onChange={handleChange("firstName")}
                  variant="outlined"
                  error={Boolean(errorMessages.firstName)}
                  helperText={
                    errorMessages.firstName && errorMessages.firstName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="lastName"
                  className={classNames(classes.textField, classes.dense)}
                  margin="dense"
                  fullWidth={true}
                  label="Last name"
                  value={lastName || ""}
                  onChange={handleChange("lastName")}
                  variant="outlined"
                  error={Boolean(errorMessages.lastName)}
                  helperText={errorMessages.lastName && errorMessages.lastName}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  id="email"
                  className={classNames(classes.textField, classes.dense)}
                  margin="dense"
                  label="Email"
                  fullWidth={true}
                  value={email || ""}
                  onChange={handleChange("email")}
                  variant="outlined"
                  error={Boolean(errorMessages.email)}
                  helperText={errorMessages.email && errorMessages.email}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  id="password"
                  className={classNames(classes.textField, classes.dense)}
                  margin="dense"
                  label="Password"
                  fullWidth={true}
                  type="password"
                  value={password || ""}
                  onChange={handleChange("password")}
                  variant="outlined"
                  error={Boolean(errorMessages.password)}
                  helperText={errorMessages.password && errorMessages.password}
                />
              </Grid>

              <Grid item xs={12}>
               <FormControl component="fieldset" className={classes.roleField}>
                  <FormLabel
                    component="legend"
                    className={classes.roleFieldLabel}
                  >
                    I will use Maxer as:
                  </FormLabel>
                  <RadioGroup
                    name="role"
                    className={classes.group}
                    value={role}
                    onChange={handleChange("role")}
                    row
                  >
                    <FormControlLabel
                      id="manager-radio-btn"
                      value="manager"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="A manager"
                    />
                    <FormControlLabel
                      id="freelancer-radio-btn"
                      value="freelancer"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="A freelancer"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {/*  <Grid item xs={12}>
                <TermsCheckbox
                  termsAccepted={termsAccepted}
                  onToggleAcceptTerms={handleChange("termsAccepted")}
                  text={
                    <Typography
                      className={
                        errorMessages.termsAccepted
                          ? classes.errorText
                          : classes.normalText
                      }
                    >
                      I agree to the{" "}
                      <MuiLink onClick={handleToggleTerms} color="primary">
                        terms of service
                      </MuiLink>
                    </Typography>
                  }
                />
              </Grid> */}

              <Grid item xs={12}>
                <Button
                  id="signup-btn"
                  variant="contained"
                  color="primary"
                  className={classes.mainCtaButton}
                  onClick={handleSignUpClick}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </Paper>
          <div>
            <Typography variant="body1" align="center">
              Already using Maxer?{" "}
              <Link to={routes.login} color="primary" weight="bold">
                Log in
              </Link>
            </Typography>
          </div>
        </form>
      </div>
    </div>
  );
}

View.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  // role: PropTypes.string,
  email: PropTypes.string,
  password: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleToggleTerms: PropTypes.func.isRequired,
  handleSignUpClick: PropTypes.func.isRequired,
  errorMessages: PropTypes.object.isRequired,
};
