// React
import React from "react";
import {useLocation, navigate} from "@reach/router";

// Context
import {FirebaseContext} from "../Firebase";
import {AuthUserContext} from "../Session";

// Components
import SimpleDialog from "../SimpleDialog";
import LoadingIndicator from "../LoadingIndicator";
import TermsOfService from "../TermsOfService";
import DialogContainer from "../DialogContainer";
import View from "./View";

// routes
import * as routes from "../../constants/routes";

// Services
import { trackEvent } from "../../services/analytics";

// Other libs
import * as yup from "yup";
import queryString from "query-string";

const deriveUsername = (firebase, firstName, lastName) => {
  // Check if userName doesn't exist yet, else add a number and try again
  return (firstName + "-" + lastName)
    .replace(/[^A-Z0-9]+/gi, "-")
    .toLowerCase();
};

const contactFieldsSchema = yup.object({
  firstName: yup
    .string()
    .test("len", "Must be between 2 and 20 characters", (val) =>
      Boolean(val.length >= 2 && val.length <= 20)
    )
    .required("Enter your first name"),
  lastName: yup
    .string()
    .test("len", "Must be between 2 and 20 characters", (val) =>
      Boolean(val.length >= 2 && val.length <= 20)
    )
    .required("Enter your last name"),
  email: yup.string().required("Enter an email").email("Enter a valid email"),
  termsAccepted: yup.bool().oneOf([true], "Field must be checked"),
  password: yup
    .string()
    .test("len", "Must be between 6 and 50 characters", (val) =>
      Boolean(val.length >= 6 && val.length <= 50)
    )
    .required("Enter a password"),
});

export default function Signup(props) {
  const [fields, setFields] = React.useState({
    firstName: "",
    lastName: "",
    role: "manager",
    email: "",
    password: "",
    termsAccepted: true,
  });
  const {email, password, firstName, lastName, role, termsAccepted} = fields;
  const [isLoading, setIsLoading] = React.useState(false);
  const [termsDialogOpen, setTermsDialogOpen] = React.useState(false);
  const [validationErrors, setValidationErrors] = React.useState({});
  const [uncaughtError, setUncaughtError] = React.useState(null);
  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);
  const firebase = React.useContext(FirebaseContext);
  const authUser = React.useContext(AuthUserContext);

  React.useEffect(() => {
    if (authUser.profileHasLoaded) {
      return navigate(routes.app);
    }
  }, [authUser.profileHasLoaded])

  React.useEffect(() => {
    setFields((f) => ({
      ...f,
      email: parsedQueries.email,
    }));
  }, [parsedQueries.email]);

  const handleChange = (prop) => (event) => {
    let newValue = event.target.value;
    if (prop === "termsAccepted") {
      const oldValue = fields.termsAccepted;
      newValue = !oldValue;
    }
    setFields({
      ...fields,
      [prop]: newValue,
    });
  };

  const handleToggleTerms = () => setTermsDialogOpen((open) => !open);

  const handleSignUpClick = () => {
    setIsLoading(true);
    validateFields()
      .then((result) => {
        if (result.isValid) {
          return firebase.auth
            .createUserWithEmailAndPassword(email, password)
        } else {
          setValidationErrors(result.validationErrors);
          setIsLoading(false);
          throw new Error({ 
            code : 403, 
            message: "validationError",
          });
        }
      })
      .then((result) => {
        const user = result ? result.user : null;
        console.log("user result", result)
        if (user) {
          let profileData = {
            uid: user.uid, // Useful for querying multiple users by id
            createdAt: firebase.createTimestamp(),
            firstName,
            lastName,
            email,
            username: deriveUsername(firebase, firstName, lastName),
            role
          };
          if (role === "freelancer") {
            profileData = {...profileData, status: "profileIncomplete"};
          }
          return firebase
            .user(user.uid)
            .set(profileData, {merge: true})
        } else {
          throw new Error({ 
            code : 403, 
            message: "An unknown error occured",
          });
        }
      })
      .then((snap) => {
        trackEvent('signed_up', { 
          userId: snap.id, 
          role: firebase.formatSnapshot(snap).role 
        })
        return navigate(routes.app);
      })
      .catch((error) => {
        // If error has a code then it means it's uncaught by validation rules.
        // This will make a dialog show
        if (error.code) {
          setUncaughtError(error.message);
          setIsLoading(false);
        }
      });
  };

  const validateFields = () => {
    return new Promise((resolve, reject) => {
      // Reset errorMessages state
      setValidationErrors({});
      contactFieldsSchema
        .validate(
          {
            firstName,
            lastName,
            email,
            password,
            termsAccepted,
          },
          {
            abortEarly: false,
          }
        )
        .then((result) => resolve({isValid: true}))
        .catch((errors) => {
          const validationErrors = {};
          for (let i = 0; i < errors.inner.length; i++) {
            const error = errors.inner[i];
            validationErrors[error.path] = error.message;
          }
          resolve({isValid: false, validationErrors});
        });
    });
  };

  const closeErrorDialog = () => setUncaughtError(null);

  if (authUser.isAuthenticating || authUser.hasAuthenticated) {
    return <LoadingIndicator message="Loading..."/>;
  }
  if (authUser.hasAuthenticated) {
    return <LoadingIndicator message="Redirecting to app..."/>;
  }
  if (isLoading) {
    return <LoadingIndicator message="Creating account..."/>;
  }

  return (
    <div>
      <DialogContainer
        open={termsDialogOpen}
        onClose={handleToggleTerms}
        title="Terms of Service"
      >
        <TermsOfService/>
      </DialogContainer>
      <SimpleDialog
        id="signup-error-dialog"
        toggleDialog={closeErrorDialog}
        open={Boolean(uncaughtError)}
        description={uncaughtError || "An error occured"}
        primaryActionText="OK"
        handlePrimaryAction={closeErrorDialog}
      />
      <View
        firstName={firstName}
        lastName={lastName}
        role={role}
        email={email}
        password={password}
        termsAccepted={termsAccepted}
        handleChange={handleChange}
        handleSignUpClick={handleSignUpClick}
        handleToggleTerms={handleToggleTerms}
        errorMessages={validationErrors}
      />
    </div>
  );
}
